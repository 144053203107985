import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
} from "recharts";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonCard,
  IonListHeader,
  IonNote,
} from "@ionic/react";
import { hashString } from "react-hash-string";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useParams } from "react-router";
import Header from "../components/Header";
import {
  getAMCIcon,
  formatScheme,
  currencyFormat,
  getPnlColor,
} from "../services/Utilities";
import "./InvestmentDetails.scss";
import SectionHeader from "../components/SectionHeader";

const InvestmentDetails: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const allHoldings: any = useSelector<RootState>(
    (state) => state.holdings.holdings.allUserHoldings
  );
  const individualHoldings: any = useSelector<RootState>(
    (state) => state.holdings.holdings.holdings
  );
  const [holding, setHolding] = useState<any>({
    folioNumber: "",
    schemeName: "",
    schemeType: "",
    amcCode: "",
    investedAmount: 0,
    currentAmount: 0,
    totalUnits: 0,
    averageNAV: 0,
    latestNAV: 0,
    latestNAVDate: "",
    pnlAmount: 0,
    pnlPercent: 0,
    xirrPercent: 0,
  });

  useEffect(() => {
    if (allHoldings != null) {
      allHoldings.map((holdings: any) => {
        holdings.userHoldings.map((folio: any) => {
          let URLKey = hashString(folio.folioNumber + folio.rtaProductCode);
          if (URLKey == id) {
            setHolding({
              folioNumber: folio.folioNumber,
              schemeName: folio.rtaSchemeName,
              schemeType: folio.schemeType,
              amcCode: folio.rtaAmcCode,
              investedAmount: folio.actualAmount,
              currentAmount: folio.currentValue,
              totalUnits: folio.units,
              averageNAV: folio.averageNav,
              latestNAV: folio.nav,
              latestNAVDate: folio.navDate.substr(0, 10),
              pnlAmount: folio.absPnl,
              pnlPercent: folio.absPnlPercent,
              xirrPercent: folio.xirr,
            });
          }
        });
      });
    }

    // check in old individual holdings if not available in family holdings
    if (individualHoldings != null) {
      individualHoldings.map((folio: any) => {
        let URLKey = hashString(folio.folioNumber + folio.rtaProductCode);
        if (URLKey == id) {
          setHolding({
            folioNumber: folio.folioNumber,
            schemeName: folio.rtaSchemeName,
            schemeType: folio.schemeType,
            amcCode: folio.rtaAmcCode,
            investedAmount: folio.actualAmount,
            currentAmount: folio.currentValue,
            totalUnits: folio.units,
            averageNAV: folio.averageNav,
            latestNAV: folio.nav,
            latestNAVDate: folio.navDate.substr(0, 10),
            pnlAmount: folio.absPnl,
            pnlPercent: folio.absPnlPercent,
            xirrPercent: folio.xirr,
          });
        }
      });
    }
  }, [id]);

  return (
    <IonPage>
      <Header title="Investment details" disableMenu={true} />
      <IonContent fullscreen className="disable-scrollbar">
        <div className="page-header">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol size="12">
                    <SectionHeader
                      title={formatScheme(holding.schemeName)}
                      subTitle={"Folio : " + holding.folioNumber}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid>
          <IonCard className="investment-details-card">
            <div className="holding-entry">
              <IonList>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>P&L Amount</h6>
                  </IonText>
                  <IonText slot="end" color={getPnlColor(holding.pnlAmount)}>
                    <h6>{currencyFormat(Math.abs(holding.pnlAmount))}</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>P&L %</h6>
                  </IonText>
                  <IonText slot="end" color={getPnlColor(holding.pnlAmount)}>
                    <h6>{Math.abs(holding.pnlPercent)}%</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>Invested</h6>
                  </IonText>
                  <IonText slot="end">
                    <h6>{currencyFormat(holding.investedAmount)}</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>Current</h6>
                  </IonText>
                  <IonText slot="end">
                    <h6>{currencyFormat(holding.currentAmount)}</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>Units</h6>
                  </IonText>
                  <IonText slot="end">
                    <h6>{holding.totalUnits}</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>Avg NAV</h6>
                  </IonText>
                  <IonText slot="end">
                    <h6>{currencyFormat(holding.averageNAV)}</h6>
                  </IonText>
                </IonItem>
                <IonItem lines="none">
                  <IonText slot="start">
                    <h6>Latest NAV</h6>
                  </IonText>
                  <IonText slot="end">
                    <h6>{currencyFormat(holding.latestNAV)}</h6>
                  </IonText>
                </IonItem>
              </IonList>
            </div>
          </IonCard>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default InvestmentDetails;
