import {
  IonCol,
  IonRow,
  IonText,
  IonCard,
} from "@ionic/react";
import { Icon } from "ionicons/dist/types/components/icon/icon";
import { currencyFormat } from "../services/Utilities";
import { getPnlColor } from "../services/Utilities";
import "./SummaryCard.scss";

interface Props {
  summary?: any;
  type?: string;
}
const SummaryCard: React.FC<Props> = ({ summary, type = "secondary" }) => {
  const showSecondaryCard = () => {
    return (
      <div className="summary-card-secondary">
        <div className="card-body">
          <IonRow>
            <IonCol>
              <IonText
                color={getPnlColor(summary ? summary.totPnlAmount : 0)}
                class="ion-text-center"
              >
                <h2>
                  {currencyFormat(summary ? Math.abs(summary.totPnlAmount) : 0)}
                  <span className="text-xs">
                    &nbsp;({summary ? Math.abs(summary.totPnlPercent) : 0}%)
                  </span>
                </h2>
              </IonText>
              <p className="ion-text-center">Your Returns</p>
            </IonCol>
          </IonRow>
        </div>
        <div className="card-footer">
          <IonRow>
            <IonCol size="5">
              <p className="ion-text-right">Invested</p>
              <h4 className="ion-text-right">
                {currencyFormat(summary ? summary.totInvested : 0)}
              </h4>
            </IonCol>
            <IonCol size="2">
              <div className="vr-line" />
            </IonCol>
            <IonCol size="5">
              <p className="ion-text-left">Current</p>
              <h4 className="ion-text-left">
                {currencyFormat(summary ? summary.totCurval : 0)}
              </h4>
            </IonCol>
          </IonRow>
        </div>
      </div>
    );
  };

  const showPrimaryCard = () => {
    return (
      <IonCard className="dashboard-summary">
        <IonRow>
          <IonCol>
            <h5 className="ion-text-left">Returns</h5>
            <IonText
              color={getPnlColor(summary ? summary.totPnlAmount : 0)}
              class="ion-text-left"
            >
              <h2>{currencyFormat(summary ? Math.abs(summary.totPnlAmount) : 0)}</h2>
            </IonText>
          </IonCol>
          <IonCol>
            <h5 className="ion-text-right">P&L</h5>
            <IonText
              color={getPnlColor(summary ? summary.totPnlPercent : 0)}
              class="ion-text-right"
            >
              <h2>{summary ? Math.abs(summary.totPnlPercent) : 0}%</h2>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h5 className="ion-text-left">Invested</h5>
            <h4 className="ion-text-left">
              {currencyFormat(summary ? summary.totInvested : 0)}
            </h4>
          </IonCol>
          <IonCol>
            <h5 className="ion-text-right">Current</h5>
            <h4 className="ion-text-right">
              {currencyFormat(summary ? summary.totCurval : 0)}
            </h4>
          </IonCol>
        </IonRow>
       
      </IonCard>
    );
  };

  let summaryCard;
  switch (type) {
    case "primary":
      summaryCard = showPrimaryCard();
      break;
    case "secondary":
      summaryCard = showSecondaryCard();
      break;
    default:
      summaryCard = showSecondaryCard();
  }

  return summaryCard;
};
export default SummaryCard;
