export const formatAmount = (amount: number) => {
  let outAmount = Math.round(amount).toString();

  if (amount > 99999.99) {
    outAmount = (Math.round((amount / 100000) * 100) / 100).toString() + "L";
  } else if (amount > 999.99) {
    outAmount = (Math.round((amount / 1000) * 100) / 100).toString() + "k";
  }
  return outAmount;
};

/* Function to shorten the scheme name till 'Fund' */
export const formatScheme = (scheme: String) => {
  if (scheme.indexOf("und") > 0) {
    return scheme.substring(0, scheme.indexOf("und") + 3);
  } else if (scheme.indexOf("UND") > 0) {
    return scheme.substring(0, scheme.indexOf("UND") + 3);
  } else {
    return scheme;
  }
};

export const getAMCIcon = (amccode: string) => {
  switch (amccode) {
    case "F":
      return "/assets/images/amc/lnt-kashly.png";
    case "K":
      return "/assets/images/amc/kotak-kashly.svg";
    case "D":
      return "/assets/images/amc/dsp-kashly.svg";
    case "B":
      return "/assets/images/amc/birla-kashly.svg";
    case "P":
      return "/assets/images/amc/icici-kashly.svg";
    case "L":
      return "/assets/images/amc/sbi-kashly.svg";
    case "H":
      return "/assets/images/amc/hdfc-kashly.svg";
    case "T":
      return "/assets/images/amc/tata-kashly.svg";
    case "PP":
      return "/assets/images/amc/ppfas-kashly.svg";
    case "RMF":
      return "/assets/images/amc/nippon-kashly.png";
    case "128":
      return "/assets/images/amc/axis-kashly.svg";
    case "117":
      return "/assets/images/amc/mirae-kashly.svg";
    case "118":
      return "/assets/images/amc/edelweiss-kashly.svg";
    default:
      return "/assets/images/amc/kashly.png";
  }
};

export const getAMCLogo = (fundName: string) => {
  fundName = fundName.toUpperCase();
  if (fundName.substr(0, 2) === "JM") {
    return "/assets/images/amc/jm-kashly.svg";
  } else if (fundName.substr(0, 3) === "LIC") {
    return "/assets/images/amc/lic-kashly.svg";
  } else if (fundName.substr(0, 3) === "BOI") {
    return "/assets/images/amc/boi-kashly.svg";
  } else if (fundName.substr(0, 3) === "ITI") {
    return "/assets/images/amc/iti-kashly.png";
  } else if (fundName.substr(0, 3) === "SBI") {
    return "/assets/images/amc/sbi-kashly.svg";
  } else if (fundName.substr(0, 3) === "DSP") {
    return "/assets/images/amc/dsp-kashly.svg";
  } else if (fundName.substr(0, 3) === "BNP") {
    return "/assets/images/amc/bnp-kashly.svg";
  } else if (fundName.substr(0, 3) === "L&T") {
    return "/assets/images/amc/lnt-kashly.png";
  } else if (fundName.substr(0, 3) === "UTI") {
    return "/assets/images/amc/uti-kashly.svg";
  } else if (fundName.substr(0, 4) === "DHFL") {
    return "/assets/images/amc/dhfl-kashly.svg";
  } else if (fundName.substr(0, 4) === "IIFL") {
    return "/assets/images/amc/iifl-kashly.svg";
  } else if (fundName.substr(0, 4) === "HSBC") {
    return "/assets/images/amc/hsbc-kashly.svg";
  } else if (fundName.substr(0, 4) === "HDFC") {
    return "/assets/images/amc/hdfc-kashly.svg";
  } else if (fundName.substr(0, 4) === "IDFC") {
    return "/assets/images/amc/idfc-kashly.svg";
  } else if (fundName.substr(0, 4) === "IDBI") {
    return "/assets/images/amc/idbi-kashly.svg";
  } else if (fundName.substr(0, 4) === "PGIM") {
    return "/assets/images/amc/pgim-kashly.png";
  } else if (fundName.substr(0, 4) === "NAVI") {
    return "/assets/images/amc/navi-kashly.png";
  } else if (fundName.substr(0, 4) === "TATA") {
    return "/assets/images/amc/tata-kashly.svg";
  } else if (fundName.substr(0, 4) === "AXIS") {
    return "/assets/images/amc/axis-kashly.svg";
  } else if (fundName.substr(0, 5) === "ICICI") {
    return "/assets/images/amc/icici-kashly.svg";
  } else if (fundName.substr(0, 5) === "KOTAK") {
    return "/assets/images/amc/kotak-kashly.svg";
  } else if (fundName.substr(0, 5) === "MIRAE") {
    return "/assets/images/amc/mirae-kashly.svg";
  } else if (fundName.substr(0, 5) === "PPFAS") {
    return "/assets/images/amc/ppfas-kashly.svg";
  } else if (fundName.substr(0, 5) === "PARAG") {
    return "/assets/images/amc/ppfas-kashly.svg";
  } else if (fundName.substr(0, 5) === "UNION") {
    return "/assets/images/amc/union-kashly.png";
  } else if (fundName.substr(0, 7) === "QUANTUM") {
    return "/assets/images/amc/quantum-kashly.svg";
  } else if (fundName.substr(0, 5) === "QUANT") {
    return "/assets/images/amc/quant-kashly.svg";
  } else if (fundName.substr(0, 6) === "ADITYA") {
    return "/assets/images/amc/birla-kashly.svg";
  } else if (fundName.substr(0, 6) === "NIPPON") {
    return "/assets/images/amc/nippon-kashly.png";
  } else if (fundName.substr(0, 6) === "CANARA") {
    return "/assets/images/amc/canara-kashly.svg";
  } else if (fundName.substr(0, 6) === "BARODA") {
    return "/assets/images/amc/baroda-kashly.svg";
  } else if (fundName.substr(0, 6) === "TAURUS") {
    return "/assets/images/amc/taurus-kashly.svg";
  } else if (fundName.substr(0, 7) === "INVESCO") {
    return "/assets/images/amc/invesco-kashly.svg";
  } else if (fundName.substr(0, 7) === "MOTILAL") {
    return "/assets/images/amc/motilal-kashly.svg";
  } else if (fundName.substr(0, 7) === "SHRIRAM") {
    return "/assets/images/amc/shriram-kashly.png";
  } else if (fundName.substr(0, 8) === "FRANKLIN") {
    return "/assets/images/amc/franklin-kashly.svg";
  } else if (fundName.substr(0, 8) === "SUNDARAM") {
    return "/assets/images/amc/sundaram-kashly.svg";
  } else if (fundName.substr(0, 8) === "MAHINDRA") {
    return "/assets/images/amc/mahindra-kashly.svg";
  } else if (fundName.substr(0, 9) === "PRINCIPAL") {
    return "/assets/images/amc/principal-kashly.svg";
  } else if (fundName.substr(0, 9) === "EDELWEISS") {
    return "/assets/images/amc/edelweiss-kashly.svg";
  } else if (fundName.substr(0, 10) === "INDIABULLS") {
    return "/assets/images/amc/indiabulls-kashly.svg";
  } else return "/assets/images/amc/kashly.png";
};

export const getSchemeTypes = (holdings: any) => {
  let chartData: any = [];
  let debt_funds = 0;
  let equity_funds = 0;
  let other_funds = 0;

  holdings.map((userHolding: any) => {
    userHolding.userHoldings.map((folio: any) => {
      switch (folio.schemeType.toLowerCase()) {
        /*Debt Funds*/
        case "debt fund":
        case "liquid fund":
        case "cash fund":
        case "liquid":
        case "debt":
        case "bond":
        case "ultra liquid":
        case "gilt":
          debt_funds = debt_funds + folio.amount;
          break;
        /*Equity Funds*/
        case "e":
        case "equity":
        case "equity fun":
        case "equity fund":
        case "elss":
        case "equity(g)":
        case "equity(s)":
        case "flexi cap":
          equity_funds = equity_funds + folio.amount;
          break;
        /*Other Funds*/
        default:
          other_funds = other_funds + folio.amount;
          break;
      }
    });
  });

  chartData[0] = debt_funds;
  chartData[1] = equity_funds;
  chartData[2] = other_funds;
  chartData[3] = debt_funds + equity_funds + other_funds;

  return chartData;
};

export const currencyFormat = (amount: number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(amount);
};

export const getPnlColor = (amount: number) => {
  let pnlColor = "dark";
  if (amount > 0) {
    pnlColor = "success";
  } else if (amount < 0) {
    pnlColor = "danger";
  }
  return pnlColor;
};

export const getImage = (byteArray: any) => {
  ////console.log(URL.createObjectURL(result.data))
  var rawImg = byteArray;
  // convert to Base64
  var b64Response = btoa(rawImg);
  var outputImg = document.createElement("img");
  outputImg.src = "data:image/png;base64," + b64Response;
  return outputImg.src;
};
