import axios from "axios";
const basicAuth =
  "Basic " +
  btoa(
    process.env.REACT_APP_SECRET_NAME + ":" + process.env.REACT_APP_SECRET_CODE
  );

/* Auth Server Request */
const authRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    Authorization: basicAuth,
  },
});

/* Private end points with token 
const privateResourceRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/json",
  },
});

privateResourceRequest.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const publicResourceRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    "content-type": "application/json",
  },
});
*/

const serverRequest = (requestType: any, contentType: any) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
      "Content-Type": contentType,
    },
  });
  if (requestType == "private") {
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
  }
  return instance;
};

export default {
  userLogin: (data: any) =>
    authRequest({
      method: "POST",
      url: "/oauth/token",
      data: new URLSearchParams(data),
    }),

  getUser: (id: string) =>
    serverRequest(
      "private",
      "application/json"
    )({
      method: "GET",
      url: "/v1/user/" + id,
    }),

  resetPasswordReq: (data: any) =>
    serverRequest(
      "public",
      "application/json"
    )({
      method: "POST",
      url: "v1/user/resetRequest",
      data: data,
    }),

  resetPassword: (data: any) =>
    serverRequest(
      "public",
      "application/json"
    )({
      method: "POST",
      url: "v1/user/reset",
      data: data,
    }),

  getFundDetails: (id: any) =>
    serverRequest(
      "public",
      "application/json"
    )({
      method: "GET",
      url: "https://connect.kashly.in/v1/mf/" + id,
    }),

  getHoldings: (id: string) =>
    serverRequest(
      "private",
      "application/json"
    )({
      method: "GET",
      url: "/v1/holdings/" + id,
    }),

  getTransactions: (id: string, startDate: string, endDate: string) =>
    serverRequest(
      "private",
      "application/json"
    )({
      method: "GET",
      url:
        "/v1/transactions/" +
        id +
        "/range?start=" +
        startDate +
        "&end=" +
        endDate,
    }),

  changeAvatar: (data: any) =>
    serverRequest(
      "private",
      "multipart/form-data"
    )({
      method: "POST",
      url: "v1/user/uploadFile",
      data: data,
    }),

  getAvatar: () =>
    serverRequest(
      "private",
      "application/octet-stream"
    )({
      method: "GET",
      url: "v1/user/downloadFile",
      responseType: 'blob'
    }),

  getActiveNfos: () =>
    serverRequest(
      "public",
      "application/json"
    )({
      method: "GET",
      url: "v1/nfo/active",
    }),

  getRecentlyClosedNfos: (count: number) =>
    serverRequest(
      "public",
      "application/json"
    )({
      method: "GET",
      url: "v1/nfo/expired/" + count,
    }),
};
