import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useLocation } from "react-router-dom";
import {
  bookmarksOutline,
  gridOutline,
  personCircleOutline,
  pieChartOutline,
  podiumOutline,
  powerOutline,
  repeatOutline,
  starOutline,
} from "ionicons/icons";
import "./Menu.scss";

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: gridOutline,
  },
  {
    title: "Investments",
    url: "/investments",
    icon: pieChartOutline,
  },
  {
    title: "Transactions",
    url: "/transactions",
    icon: repeatOutline,
  },
  {
    title: "New Funds",
    url: "/nfo",
    icon: bookmarksOutline,
  },
  {
    title: "Account",
    url: "/account",
    icon: personCircleOutline,
  },
  {
    title: "Logout",
    url: "/logout",
    icon: powerOutline,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const userProfile: any = useSelector<RootState>((state) => state.user.user);
  const avatar: any = useSelector<RootState>((state) => state.user.avatar);
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    createdAt: "",
  });
  useEffect(() => {
    if (userProfile != null) {
      setUser(userProfile);
    }
  }, [userProfile]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <div className="menu-header">
          <div className="menu-avatar">
          <img src={avatar ? URL.createObjectURL(avatar) : "/assets/images/avatar.svg"} />
          </div>
          <h5>{user.firstName}</h5>
          <p>{user.email}</p>
        </div>
        <IonList id="menu-list">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
