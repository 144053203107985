import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "../store/reducers";
import Header from "../components/Header";

import {
  hasFamily,
  showUserFolios,
  showFamilyFolios,
  showNOFamilyFolios,
} from "../services/HoldingServices";

const Investments: React.FC = () => {
  const { id } = useParams<{ id: any }>();

  const folios: any = useSelector<RootState>(
    (state) => state.holdings.holdings
  );
  const userProfile: any = useSelector<RootState>((state) => state.user.user);
  const [selectedTab, setSelectedTab] = useState("0");

  useEffect(() => {
    if (id == "user") {
      setSelectedTab("0");
    }
    if (id == "family") {
      setSelectedTab("1");
    }
  }, [id]);

  const showTabContent = (tabOption: string) => {
    if (tabOption == "0") {
      return showUserFolios(folios.allUserHoldings, userProfile.firstName);
    }
    if (tabOption == "1") {
      if (hasFamily(userProfile)) {
        return showFamilyFolios(folios.allUserHoldings, userProfile.firstName);
      } else {
        return showNOFamilyFolios();
      }
    }
  };

  return (
    <IonPage>
      <Header title="Investments" />
      <IonContent className="disable-scrollbar">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonSegment
                mode="ios"
                value={selectedTab}
                onIonChange={(e) => setSelectedTab(e.detail.value!)}
              >
                <IonSegmentButton value="0">
                  <IonLabel>Your Holdings</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="1">
                  <IonLabel>Family Holdings</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          {showTabContent(selectedTab)}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Investments;
