import {
  IonBackButton,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
} from "@ionic/react";
import { useEffect } from "react";

interface Props {
  title?: string;
  branded?: boolean;
  disableMenu?: boolean;
}
const Header: React.FC<Props> = ({ title, branded, disableMenu }) => {
  let toolbarStyle = "";
  let menuIconColor = "dark";
  if (branded) {
    toolbarStyle = "branded-toolbar";
    menuIconColor = "white";
  }

  useEffect(() => {
    document.title = String(title);
  }, [title]);

  return (
    <IonToolbar className={toolbarStyle}>
      <IonButtons slot="start">
        {disableMenu ? (
          <IonBackButton defaultHref="home" />
        ) : (
          <IonMenuButton color={menuIconColor} />
        )}
      </IonButtons>
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  );
};
export default Header;
