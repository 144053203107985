import * as actions from "./actions";
import API from "../../api";

export const storeHoldings = (data: any) => {
  return {
    type: actions.HOLDINGS,
    data: data,
  };
};


export const getHoldings = (user: string) => {
  return function (dispatch: any) {
    API.getHoldings(user)
      .then((result) => {
        dispatch(storeHoldings(result.data.data));
      })
      .catch((error) => {
        console.log("failed to retrieve hodings");
      });
  };
};