import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import { chatbubbles } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";


import Header from "../components/Header";
import SummaryCard from "../components/SummaryCard";
import SectionHeader from "../components/SectionHeader";

import { currencyFormat, getSchemeTypes } from "../services/Utilities";
import {
  hasFamily,
  getUserSummary,
  getFamilySummary,
} from "../services/HoldingServices";
import "./Dashboard.scss";

const Dashboard: React.FC = () => {
  const userProfile: any = useSelector<RootState>((state) => state.user.user);
  const holdings: any = useSelector<RootState>(
    (state) => state.holdings.holdings
  );
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [chartData, setChartData] = useState([]);
  const [userSummary, setUserSummary] = useState<any>({
    totInvested: 0,
    totCurval: 0,
    totPnlAmount: 0,
    totPnlPercent: 0,
  });

  const [familySummary, setFamilySummary] = useState<any>({
    totInvested: 0,
    totCurval: 0,
    totPnlAmount: 0,
    totPnlPercent: 0,
  });

  useEffect(() => {
    if (holdings && userProfile) {
      setUserName(userProfile.firstName);
      setUserId(userProfile.userName);
      setChartData(getSchemeTypes(holdings.allUserHoldings));
      setUserSummary(
        getUserSummary(holdings.allUserHoldings, userProfile.firstName)
      );
      if (hasFamily(userProfile)) {
        setFamilySummary(getFamilySummary(holdings));
      }
    }
  }, [holdings, userProfile]);

  return (
    <IonPage>
      <IonHeader>
        <Header title="Dashboard" />
      </IonHeader>

      <IonContent className="disable-scrollbar">
        <div className="hero-section">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol size="12" size-md="8">
                    <h1>Welcome, {userName}!</h1>
                    <p>You have 0 new messages</p>
                  </IonCol>
                  <IonCol size="12" size-md="4">
                    <IonButton
                      href={
                        "https://api.whatsapp.com/send/?phone=919562050863&text=Hi%2C+This+is+" +
                        userName +
                        "(" +
                        userId +
                        ").+I+would+like+to+get+some+advice+on+my+investments." +
                        "&app_absent=0"
                      }
                      target="_blank"
                    >
                      <IonIcon slot="start" icon={chatbubbles} />
                      Message RM
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid id="dashboard-content">
          <IonRow>
            {hasFamily(userProfile) ? (
              <IonCol size="12" size-md="6">
                <SectionHeader
                  title="Family Holdings"
                  subTitle="Keep track of your family investments"
                />
                <Link className="holdings-card-link" to={"/investments/family"}>
                  <SummaryCard summary={familySummary} type="primary" />
                </Link>
                <SectionHeader
                  title="Your Holdings"
                  subTitle="Overview of your own investments"
                />
                <Link className="holdings-card-link" to={"/investments/user"}>
                  <SummaryCard summary={userSummary} type="primary" />
                </Link>
              </IonCol>
            ) : (
              <IonCol size="12" size-md="6">
                <SectionHeader
                  title="Overview"
                  subTitle="Keep track of your investments"
                />
                <Link className="holdings-card-link" to={"/investments/user"}>
                <SummaryCard summary={userSummary} type="primary" />
                </Link>
              </IonCol>
            )}

            <IonCol size="12" size-md="6">
              <SectionHeader
                title="Asset Allocation"
                subTitle="Summary of your asset allocation"
              />
              <IonCard className="holding-pattern">
                <IonRow>
                  <IonCol size="2">
                    <div className="asset-icon primary-icon">
                      <div className="asset-img">
                        <img src="/assets/images/debt-icon.svg" />
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="7">
                    <p>Debt</p>
                    <h4>
                      {chartData[0]
                        ? currencyFormat(chartData[0])
                        : currencyFormat(0)}
                    </h4>
                    <IonProgressBar
                      className="weightage"
                      color="primary"
                      value={chartData[0] ? chartData[0] / chartData[3] : 0}
                    ></IonProgressBar>
                  </IonCol>
                  <IonCol size="3" className="ion-text-right">
                    <h6>
                      {chartData[0]
                        ? Math.round((chartData[0] / chartData[3]) * 100)
                        : 0}
                      %
                    </h6>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="2">
                    <div className="asset-icon danger-icon">
                      <div className="asset-img">
                        <img src="/assets/images/equity-icon.svg" />
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="7">
                    <p>Equity</p>
                    <h4>
                      {chartData[1]
                        ? currencyFormat(chartData[1])
                        : currencyFormat(0)}
                    </h4>
                    <IonProgressBar
                      className="weightage"
                      color="danger"
                      value={chartData[1] ? chartData[1] / chartData[3] : 0}
                    ></IonProgressBar>
                  </IonCol>
                  <IonCol size="3" className="ion-text-right">
                    <h6>
                      {chartData[1]
                        ? Math.round((chartData[1] / chartData[3]) * 100)
                        : 0}
                      %
                    </h6>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="2">
                    <div className="asset-icon warning-icon">
                      <div className="asset-img">
                        <img src="/assets/images/hybrid-icon.svg" />
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="7">
                    <p>Others</p>
                    <h4>
                      {chartData[2]
                        ? currencyFormat(chartData[2])
                        : currencyFormat(0)}
                    </h4>
                    <IonProgressBar
                      className="weightage"
                      color="warning"
                      value={chartData[2] ? chartData[2] / chartData[3] : 0}
                    ></IonProgressBar>
                  </IonCol>
                  <IonCol size="3" className="ion-text-right">
                    <h6>
                      {chartData[2]
                        ? Math.round((chartData[2] / chartData[3]) * 100)
                        : 0}
                      %
                    </h6>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
