import React, { useContext } from "react";
import { UserContext } from "../App";

const Logout: React.FC = () => {
  const user = useContext(UserContext);
  localStorage.removeItem("token");
  localStorage.removeItem("logged-user");
  user.setIsLoggedIn(false);
  return <></>;
};

export default Logout;
