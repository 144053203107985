import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import API from "../api";
import "./Forgot.scss";

import { validateResetRequestForm } from "../services/AuthServices";

const Forgot: React.FC = () => {
  const [resetForm, setResetForm] = useState({
    email: "",
    resetText: "",
    resetIcon: "/assets/images/reset.svg",
    loading: false,
    error: false,
    message: "",
    status: "",
  });
  const apiData = {
    email: resetForm.email,
  };
  useEffect(() => {
    setResetForm({
      ...resetForm,
      resetText:
        "We get it, stuff happens. Just enter your email address below and we'll send you reset instructions.",
    });
  }, []);

  const resetClick = () => {
    let validationError = validateResetRequestForm(resetForm);

    if (validationError === "") {
      setResetForm({
        ...resetForm,
        loading: true,
      });

      API.resetPasswordReq(apiData)
        .then((result) => {
          if (result.data.status === "success") {
            setResetForm({
              ...resetForm,
              email: "",
              status: "success",
              resetIcon: "/assets/images/email.svg",
              resetText: "We have sent a new password reset link to your email",
            });
          } else {
            setResetForm({
              ...resetForm,
              error: true,
              message: "You're not registered in Kashly",
            });
          }
        })
        .catch((error) => {
          setResetForm({
            ...resetForm,
            error: true,
            message: validationError,
          });
        });
    } else {
      setResetForm({
        ...resetForm,
        error: true,
        message: validationError,
      });
    }
  };

  return (
    <IonPage>
      <IonContent class="ion-no-padding ion-no-margin">
        <IonGrid
          class="ion-no-padding ion-no-margin"
          className="grid-max-height"
        >
          <IonRow className="row-max-height">
            <IonCol
              class="ion-text-center ion-hide-lg-down"
              className="web-wrapper max-height"
              size="12"
              size-lg="7"
              size-xl="8"
            >
              <br />
              <br />
              <br />
              <h1>Welcome to Kashly</h1>
              <p>
                A smart application for the smart investors. One single
                application for all the platforms. Let it be Android, iOS,
                Windows or even Web.
              </p>
              <div className="web-content-footer">
                <p>
                  Kashly is only an aggregation platform that collects
                  investment details of our customers from the mutual fund
                  registrars. The application is not an order placement platform
                  hence Kashly is not liable or responsible and does not
                  represent or warrant for any damages regarding non-execution
                  of orders or any incorrect execution of orders with regard to
                  the funds that happen outside of our platform.
                  <br />
                  <br />
                  <br />
                  <b>Disclaimer</b>
                  <br />
                  Mutual Funds investments are subject to market risks, read all
                  scheme related documents carefully. Past performance is not an
                  indicator for future returns.
                </p>
              </div>
            </IonCol>
            <IonCol
              size="12"
              size-lg="5"
              size-xl="4"
              class="ion-padding"
              className="max-height"
            >
              <div className="forgot-wrapper">
                <div className="forgot-header">
                  <div className="kashly-logo">
                    <img
                      src="/assets/images/reset.svg"
                      alt="kashly-password-forgot"
                    />
                  </div>
                  <h1 className="ion-no-padding ion-no-margin">
                    {resetForm.status === "success"
                      ? "You're Done!"
                      : "Forgot Password"}
                  </h1>
                  <p>{resetForm.resetText}</p>
                </div>
                <IonList>
                  {resetForm.status === "success" ? null : (
                    <>
                      <IonInput
                      placeholder="Email"
                        value={resetForm.email}
                        onIonChange={(e: any) =>
                          setResetForm({
                            ...resetForm,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                      <IonButton
                        id="reset-button"
                        fill="solid"
                        expand="block"
                        onClick={resetClick}
                      >
                        <b>Reset Password</b>
                      </IonButton>
                    </>
                  )}
                  <br />
                  <IonText class="ion-text-center" className="text-s">
                    <a href="/login">
                      <p>Login Now</p>
                    </a>
                  </IonText>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonToast
            color="warning"
            isOpen={resetForm.error}
            onDidDismiss={() =>
              setResetForm({
                ...resetForm,
                error: false,
              })
            }
            message={resetForm.message}
            position="bottom"
            duration={1000}
          />
        </IonGrid>
      </IonContent>

      {/*
    <IonPage>
      <IonContent fullscreen class="ion-no-padding ion-no-margin">
        <IonGrid
          class="ion-no-padding ion-no-margin"
          className="grid-max-height"
        >
          <IonRow className="row-max-height">
            <IonCol
              class="ion-text-center ion-hide-xl-down"
              className="web-wrapper max-height"
              size="12"
              size-lg="7"
              size-xl="8"
            >
              <br />
              <br />
              <br />
              <h1>Welcome to Kashly!</h1>
              <p>
                A smart application for the smart investors. One single
                application for all the platforms. Let it be Android, iOS,
                Windows or even Web.
              </p>
              <i className="bx bxl-windows   bx-md"></i>
              <i className="bx bxl-apple   bx-md"></i>
              <i className="bx bxl-android   bx-md"></i>
              <div className="web-content-footer">
                <p className="text-xs">
                  Kashly is only an aggregation platform that collects
                  investment details of our customers from the mutual fund
                  registrars. The application is not an order placement platform
                  hence Kashly is not liable or responsible and does not
                  represent or warrant for any damages regarding non-execution
                  of orders or any incorrect execution of orders with regard to
                  the funds that happen outside of our platform.
                  <br />
                  <br />
                  <br />
                  <b>Disclaimer</b>
                  <br />
                  Mutual Funds investments are subject to market risks, read all
                  scheme related documents carefully. Past performance is not an
                  indicator for future returns.
                  <br />
                  ARN - 186781
                </p>
              </div>
            </IonCol>
            <IonCol
              size="12"
              size-lg="5"
              size-xl="4"
              class="ion-padding"
              className="max-height"
            >
              <div className="reset-wrapper">
                <div className="ion-text-center">
                  <img
                    src={resetForm.resetIcon}
                    className="app-reset-img"
                    alt="kashly-password-reset"
                  />
                </div>
                <IonList>
                  <IonText color="primary" class="ion-text-center">
                    <h1 className="ion-no-padding ion-no-margin">
                      {resetForm.status === "success"
                        ? "You're Done!"
                        : "Forgot Password"}
                    </h1>
                  </IonText>
                  <br />
                  <IonText class="ion-text-center" className="text-s">
                    <p>{resetForm.resetText}</p>
                  </IonText>
                  <br />
                  {resetForm.status === "success" ? null : (
                    <>
                      <IonItem lines="none">
                        <IonInput
                          value={resetForm.email}
                          onIonChange={(e: any) =>
                            setResetForm({
                              ...resetForm,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </IonItem>
                      <br />
                      <IonButton
                      id="reset-button"
                        fill="solid"
                        expand="block"
                        onClick={resetClick}
                      >
                        <IonText color="light">Reset Password</IonText>
                      </IonButton>
                    </>
                  )}
                  <br />
                  <IonText class="ion-text-center" className="text-s">
                    <a href="/login">
                      <p>Login Now</p>
                    </a>
                  </IonText>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          
        </IonGrid>
      </IonContent>
          */}
    </IonPage>
  );
};

export default Forgot;
