import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import {
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonPage,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers";
import { getAMCLogo, formatScheme } from "../services/Utilities";
import moment from "moment";

import Header from "../components/Header";
import "./Nfo.scss";
import { cash, cashOutline, chatbubbles } from "ionicons/icons";
import SectionHeader from "../components/SectionHeader";

const Nfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const userProfile: any = useSelector<RootState>((state) => state.user.user);
  const activeNFOs: any = useSelector<RootState>(
    (state) => state.trxns.activeNFO
  );
  const closedNFOs: any = useSelector<RootState>(
    (state) => state.trxns.closedNFO
  );
  const [showDetails, setShowDetails] = useState(false);
  const [selectedNFO, setSelectedNFO] = useState({
    id: "",
    schemeName: "",
    schemeType: "",
    schemeCategory: "",
    objective: "",
    minSubscription: "",
    loadDetails: "",
    launchDate: "",
    closureDate: "",
  });

  const [user, setUser] = useState({
    id: "",
    firstName: "",
  });

  useEffect(() => {
    if (userProfile != null && userProfile.firstName) {
      setUser({ id: userProfile.userName, firstName: userProfile.firstName });
    }
  }, [userProfile]);

  const getDisplayDate = (date: string) => {
    var displayDate = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY");
    return new Date(displayDate!).toDateString().substr(4, 12);
  };

  const getActiveNFOList = (activeNFOs: any) => {
    var nfoList: any = [];
    if (activeNFOs) {
      activeNFOs.map((nfo: any) => {
        nfoList.push(
          <Link to={"/nfo/" + nfo.id}>
            <IonRow>
              <IonCol>
                <IonCard className="nfo-card">
                  <IonRow>
                    <IonCol size="1">
                      <div className="amc-logo">
                        <img alt="amc-logo" src={getAMCLogo(nfo.schemeName)} />
                      </div>
                    </IonCol>
                    <IonCol size="11" size-md="8">
                      <h4> {formatScheme(nfo.schemeName)}</h4>
                      <p>
                        {getDisplayDate(nfo.launchDate.substr(0, 10)) +
                          " ~ " +
                          getDisplayDate(nfo.closureDate.substr(0, 10))}
                      </p>
                    </IonCol>
                    <IonCol size="3" class="ion-hide-md-down">
                      <IonButton>
                        <IonIcon slot="start" icon={cashOutline} />
                        Subscribe
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
          </Link>
        );
      });
    }
    return nfoList;
  };

  const getClosedNFOList = (closedNFOs: any) => {
    var nfoList: any = [];
    if (closedNFOs) {
      closedNFOs.map((nfo: any) => {
        nfoList.push(
          <Link to={"/nfo/" + nfo.id}>
            <IonRow>
              <IonCol>
                <IonCard className="nfo-card">
                  <IonRow>
                    <IonCol size="1">
                      <div className="amc-logo">
                        <img alt="amc-logo" src={getAMCLogo(nfo.schemeName)} />
                      </div>
                    </IonCol>
                    <IonCol size="11">
                      <h4> {formatScheme(nfo.schemeName)}</h4>
                      <p>
                        {"Closed on " +
                          getDisplayDate(nfo.closureDate.substr(0, 10))}
                      </p>
                    </IonCol>
                  </IonRow>
                </IonCard>
              </IonCol>
            </IonRow>
          </Link>
        );
      });
    }
    return nfoList;
  };

  const setNFODetails = (item: any) => {
    if (item) {
      setSelectedNFO({
        id: "",
        schemeName: item.schemeName ? item.schemeName : "",
        schemeType: item.schemeType ? item.schemeType : "",
        schemeCategory: item.schemeCategory ? item.schemeCategory : "",
        objective: item.objective ? item.objective : "Not Available",
        minSubscription: item.minSubscription ? item.minSubscription : "",
        loadDetails: item.loadDetails ? item.loadDetails : "NIL",
        launchDate: item.launchDate ? item.launchDate : "",
        closureDate: item.closureDate ? item.closureDate : "",
      });
    }
  };

  useEffect(() => {
    if (id) {
      setShowDetails(true);
      if (activeNFOs) {
        activeNFOs.map((item: any) => {
          if (item.id == id) {
            setNFODetails(item);
          }
        });
      }
      if (closedNFOs) {
        closedNFOs.map((item: any) => {
          if (item.id == id) {
            setNFODetails(item);
          }
        });
      }
    }
  }, []);

  return (
    <IonPage>
      <Header title="New Fund Offers" disableMenu={showDetails} />
      <IonContent className="disable-scrollbar">
        {!showDetails ? (
          <IonGrid className="nfo-list">
            <IonRow>
              <IonCol>
                <SectionHeader
                  title="Currently Open"
                  subTitle="NFOs currently open for subscription"
                />
              </IonCol>
            </IonRow>
            {getActiveNFOList(activeNFOs)}
            <br />
            <br />
            <IonRow>
              <IonCol>
                <SectionHeader
                  title="Recently Closed"
                  subTitle="NFOs closed recently"
                />
              </IonCol>
            </IonRow>
            {getClosedNFOList(closedNFOs)}
          </IonGrid>
        ) : (
          <>
            <div className="page-header">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol size="12" size-md="8">
                        <SectionHeader
                          title={formatScheme(selectedNFO.schemeName)}
                          subTitle={
                            getDisplayDate(
                              selectedNFO.launchDate.substring(0, 10)
                            ) +
                            " ~ " +
                            getDisplayDate(
                              selectedNFO.closureDate.substring(0, 10)
                            )
                          }
                        />
                      </IonCol>
                      <IonCol size="12" size-md="4">
                        <IonButton
                          href={
                            "https://api.whatsapp.com/send/?phone=919353234979&text=Hi%2C+This+is+" +
                            user.firstName +
                            "(" +
                            user.id +
                            ").+I+would+like+to+know+more+about+the+NFO+'" +
                            formatScheme(selectedNFO.schemeName) +
                            "'&app_absent=0"
                          }
                          target="_blank"
                        >
                          <IonIcon slot="start" icon={chatbubbles} />
                          Get Advice
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <IonGrid>
              <IonCard className="nfo-detail-card">
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <h4>Scheme Type</h4>
                    <p>{selectedNFO.schemeType}</p>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <h4>Scheme Category</h4>
                    <p>{selectedNFO.schemeCategory}</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <h4>Min Subscription</h4>
                    <p>{selectedNFO.minSubscription}</p>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonCard className="nfo-detail-card">
                <IonRow>
                  <IonCol>
                    <h4>Objective</h4>
                    <p>{selectedNFO.objective}</p>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonCard className="nfo-detail-card">
                <IonRow>
                  <IonCol>
                    <h4>Load Details</h4>
                    <p>{selectedNFO.loadDetails}</p>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Nfo;
