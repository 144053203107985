import * as actions from "./actions";
import API from "../../api";

export const storeTransactions = (data: any) => {
  return {
    type: actions.TRANSACTIONS,
    data: data,
  };
};

export const getTransactions = (
  user: string,
  startDate: string,
  endDate: string
) => {
  return function (dispatch: any) {
    API.getTransactions(user, startDate, endDate)
      .then((result) => {
        dispatch(storeTransactions(result.data.data));
      })
      .catch((error) => {
        console.log("failed to retrieve transactions");
      });
  };
};

export const storeHoldings = (data: any) => {
  return {
    type: actions.HOLDINGS,
    data: data,
  };
};

export const getHoldings = (user: string) => {
  return function (dispatch: any) {
    API.getHoldings(user)
      .then((result) => {
        dispatch(storeHoldings(result.data.data));
      })
      .catch((error) => {
        console.log("failed to retrieve hodings");
      });
  };
};

export const storeActiveNFOs = (data: any) => {
  return {
    type: actions.ACTIVE_NFO,
    data: data,
  };
};

export const getActiveNFOs = () => {
  return function (dispatch: any) {
    API.getActiveNfos()
      .then((result) => {
        dispatch(storeActiveNFOs(result.data.data));
      })
      .catch((error) => {
        console.log("failed to retrieve active NFOs");
      });
  };
};

export const storeClosedNFOs = (data: any) => {
  return {
    type: actions.CLOSED_NFO,
    data: data,
  };
};

export const getClosedNFOs = () => {
  return function (dispatch: any) {
    API.getRecentlyClosedNfos(10)
      .then((result) => {
        dispatch(storeClosedNFOs(result.data.data));
      })
      .catch((error) => {
        console.log("failed to retrieve closed NFOs");
      });
  };
};
