import "./SectionHeader.scss";
interface Props {
  title?: any;
  subTitle?: any;
}
const SectionHeader: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <div className="section-header">
      {title ? <h2>{title}</h2> : null}
      {subTitle ? <p>{subTitle}</p> : null}
    </div>
  );
};
export default SectionHeader;
