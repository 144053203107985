import ReactDOM from "react-dom";
import { serviceWorkerUpdate } from "./store/actions";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { rootReducer } from "./store/reducers";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import App from "./App";

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

serviceWorkerRegistration.register({
  onSuccess: () => {console.log("Servie Worker Registered");},
  onUpdate: (registration) => {
    console.log("Servie Worker Updated");
    store.dispatch(serviceWorkerUpdate(registration));
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);



reportWebVitals();
