import { IonApp, IonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { RootState } from "./store/reducers";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Login from "./pages/Login"
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import React, { useState, useContext, useEffect } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
//import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/styles.scss";
import PrivateRoute from "./components/PrivateRoute";



interface IUserManager {
  setIsLoggedIn: Function;
}

const user: IUserManager = {
  setIsLoggedIn: () => {},
};
export const UserContext = React.createContext<IUserManager>(user);

const App: React.FC = () => {
  
  const isServiceWorkerUpdated: any = useSelector<RootState>(
    (state) => state.worker.serviceWorkerUpdated
  );
  const serviceWorkerRegistration: any = useSelector<RootState>(
    (state) => state.worker.serviceWorkerRegistration
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const user = useContext(UserContext);
  user.setIsLoggedIn = setIsLoggedIn;

  useEffect(() => {
    if (isServiceWorkerUpdated) {
      setShowAlert(true);
    }
  }, [isServiceWorkerUpdated]);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e: any) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <IonApp>
      <UserContext.Provider value={user}>
        <IonReactRouter>
          <Route path="/" component={isLoggedIn ? PrivateRoute : Login} />
          <Route path="/login" component={Login}  />
          <Route path="/reset" component={Reset}  />
          <Route path="/forgot" component={Forgot}  />
        </IonReactRouter>
      </UserContext.Provider>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Update Kashly"}
        message={"An updated version of Kashly is available. Update now ?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowAlert(false);
            },
          },
          {
            text: "Update",
            handler: () => {
              setShowAlert(false);
              updateServiceWorker();
            },
          },
        ]}
      />
    </IonApp>
  );
};

export default App;
