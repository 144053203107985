import { IonRow, IonCol } from "@ionic/react";
import HoldingCard from "../components/HoldingCard";
import SectionHeader from "../components/SectionHeader";
import SummaryCard from "../components/SummaryCard";

/* check whether the profile contains family number or not */
export const hasFamily = (userProfile: any) => {
  if (userProfile != null && userProfile.profile.familyNumber != null)
    return true;
  else return false;
};

/* return family folios list  with member names */
export const showFamilyFolios = (holdings: any, loggedUser: any) => {
  let familyArray: any = [];
  holdings.map((holdingSummary: any) => {
    /* skip folio owner name if matches with logged in user */
    if (loggedUser != holdingSummary.firstName) {
      familyArray.push(
        <>
          <IonRow>
            <IonCol>
              <SectionHeader title={holdingSummary.firstName + "'s Holdings"} />
            </IonCol>
          </IonRow>
          <SummaryCard
            summary={getUserSummary(holdings, holdingSummary.firstName)}
          />
        </>
      );
    }
    /* skip folios of the logged in user in family holdings*/
    if (loggedUser != holdingSummary.firstName) {
      holdingSummary.userHoldings.map((folio: any) => {
        familyArray.push(<HoldingCard folio={folio} />);
      });
    }
  });
  return familyArray;
};

export const showNOFamilyFolios = () => {
  return (
    <>
      <div className="app-empty-transactions">
        <img src="/assets/images/family.svg" alt="no-family-members-linked" />
      </div>
      <br />
      <h6 className="ion-text-center">No Family Members</h6>
      <p className="ion-text-center text-xs">
        You have not added any family members here. Please contact
        support@kashly.in to get your Kashly member's folios listed here.
      </p>
    </>
  );
};

/* return individual folios list  with member name */
export const showUserFolios = (holdings: any, loggedUser: any) => {
  let holdingsArray: any = [];
  holdings.map((holdingSummary: any) => {
    /* include owner name if matches with logged in user*/
    if (loggedUser == holdingSummary.firstName) {
      holdingsArray.push(
        <>
          <IonRow>
            <IonCol>
              <SectionHeader title={holdingSummary.firstName + "'s Holdings"} />
            </IonCol>
          </IonRow>
          <SummaryCard
            summary={getUserSummary(holdings, holdingSummary.firstName)}
          />
        </>
      );
    }
    /* include only user holdings*/
    if (loggedUser == holdingSummary.firstName) {
      holdingSummary.userHoldings.map((folio: any) => {
        holdingsArray.push(<HoldingCard folio={folio} />);
      });
    }
  });
  return holdingsArray;
};

export const getUserSummary = (allHoldings: any, user: string) => {
  let summary = {};
  if (allHoldings) {
    allHoldings.map((holding: any) => {
      if (holding.firstName == user) {
        summary = {
          totInvested: holding.totalInvested,
          totCurval: holding.currentValue,
          totPnlAmount: holding.pnlAmount,
          totPnlPercent: holding.pnlPercent,
        };
      }
    });
  }
  return summary;
};

export const getFamilySummary = (holdings: any) => {
  let summary = {};
  if (holdings) {
    summary = {
      totInvested: holdings.totalInvested,
      totCurval: holdings.currentValue,
      totPnlAmount: holdings.pnlAmount,
      totPnlPercent: holdings.pnlPercent,
    };
  }
  return summary;
};
