import * as actions from "./actions";

export const workerUpdate = (data: any) => {
    return {
      type: actions.SWUPDATE,
      data: data,
    };
  };

export const serviceWorkerUpdate = (data: any) => {
    return function (dispatch: any) {
      dispatch(workerUpdate(data));
    };
  };
