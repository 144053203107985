import { combineReducers } from 'redux';
import authReducer from './user';
import trxnReducer from './transactions';
import swReducer from './serviceworker';
import holdingReducer from "./holdings";

export const rootReducer =  combineReducers({
  user: authReducer,
  trxns: trxnReducer,
  holdings: holdingReducer,
  worker: swReducer,
  
})

export type RootState = ReturnType<typeof rootReducer>;
