import * as actions from "../actions/actions";

const initialState = {
  holdings: null,
};

const storeHoldings = (state: any, action: any) => {
  return {
    ...state,
    holdings: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.HOLDINGS:
      return storeHoldings(state, action);
    default:
      return state;
  }
};

export default reducer;
