import React from "react";
import { IonRouterOutlet, IonTabs, IonSplitPane } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import {
  gridOutline,
  podiumOutline,
  personCircleOutline,
} from "ionicons/icons";
import Menu from "./Menu";
import Dashboard from "../pages/Dashboard";
import Investments from "../pages/Investments";
import Account from "../pages/Account";
import InvestmentDetails from "../pages/InvestmentDetails";
import TransactionDetails from "../pages/TransactionDetails";
import Logout from "../pages/Logout";
import Nfo from "../pages/Nfo";
import Trans from "../pages/Trans";

const PrivateRoute: React.FC = () => {
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonRouterOutlet id="main">
        <IonTabs>
          <IonRouterOutlet id="main">
            <Route path="/dashboard" component={Dashboard} exact={true} />
            <Route path="/investments/:id" component={Investments} exact={true} />
            <Route path="/investments" component={Investments} exact={true} />
            <Route
              path="/investment-details/:id"
              component={InvestmentDetails}
              exact={true}
            />
            <Route path="/nfo/:id" component={Nfo}  exact={true}/>
            <Route path="/nfo" component={Nfo}  exact={true}/>
            <Route path="/transactions" component={Trans}  exact={true}/>
            <Route
              path="/transaction-details/:id"
              component={TransactionDetails}
            />
            <Route path="/account" component={Account} />
            <Route path="/logout" component={Logout} />
            <Route
              path="/"
              render={() => <Redirect to="/dashboard" />}
              exact={true}
            />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" className="ion-hide-md-up">
            <IonTabButton tab="dashboard" href="/dashboard">
              <IonIcon icon={gridOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>

            <IonTabButton tab="investments" href="/investments">
              <IonIcon icon={podiumOutline} />
              <IonLabel>Investments</IonLabel>
            </IonTabButton>

            <IonTabButton tab="account" href="/account">
              <IonIcon icon={personCircleOutline} />
              <IonLabel>Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonRouterOutlet>
    </IonSplitPane>
  );
};
export default PrivateRoute;
