import * as actions from "../actions/actions";

const initialState = {
  user: null,
  avatar: null,
};

const storeProfile = (state: any, action: any) => {
  return {
    ...state,
    user: action.data,
  };
};

const storeAvatar = (state: any, action: any) => {
  return {
    ...state,
    avatar: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.PROFILE:
      return storeProfile(state, action);
    case actions.AVATAR:
      return storeAvatar(state, action);
    default:
      return state;
  }
};

export default reducer;
