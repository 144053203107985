import React from "react";
import { IonRow, IonCol, IonText, IonCard } from "@ionic/react";
import { hashString } from "react-hash-string";
import {
  getPnlColor,
  formatScheme,
  getAMCIcon,
  formatAmount,
} from "../services/Utilities";
import { Link } from "react-router-dom";
import "./HoldingCard.scss";

interface HoldingCardProps {
  folio?: any;
}

const HoldingCard: React.FC<HoldingCardProps> = ({ folio }) => {
  let URLKey = hashString(folio.folioNumber + folio.rtaProductCode);
  return (
    <Link className="holdings-card-link" to={"../investment-details/" + URLKey}>
      <IonCard className="holdings-card">
        
        <IonRow>
          <IonCol size="1">
            <div className="amc-logo">
              <img src={getAMCIcon(folio.rtaAmcCode)} />
            </div>
          </IonCol>
          <IonCol size="11">
            <h3>{formatScheme(folio.rtaSchemeName)}</h3>
            <p> Folio : {folio.folioNumber}</p>
          </IonCol>
        </IonRow>
        <div className="hr-line" />
        <IonRow>
          <IonCol>
            <h5 className="ion-text-left">P&L</h5>
          </IonCol>
          <IonCol>
            <h5 className="ion-text-center">Invested</h5>
          </IonCol>
          <IonCol>
            <h5 className="ion-text-right">Current</h5>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color={getPnlColor(folio.absPnl)}>
              <h4 className="ion-text-left">{Math.abs(folio.absPnlPercent)}%</h4>
            </IonText>
          </IonCol>
          <IonCol>
            <h4 className="ion-text-center">
              &#8377;{formatAmount(folio.actualAmount)}
            </h4>
          </IonCol>
          <IonCol>
            <h4 className="ion-text-right">
              &#8377;{formatAmount(folio.currentValue)}
            </h4>
          </IonCol>
        </IonRow>
      </IonCard>
    </Link>
  );
};

export default HoldingCard;
