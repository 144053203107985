import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonList,
  IonInput,
  IonButton,
  IonToast,
  IonText,
  IonLoading,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
} from "@ionic/react";
import moment from "moment";
import { UserContext } from "../App";
import API from "../api";
import {
  storeProfile,
  getHoldings,
  getActiveNFOs,
  getClosedNFOs,
  getTransactions,
  getAvatar,
} from "../store/actions";
import "./Login.scss";

const Login: React.FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginError, setLoginError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const authData = {
    username: userName,
    password: password,
    grant_type: "password",
    scopes: "write",
  };

  const loginClick = () => {
    if (userName === "" || password === "") {
      setLoginError(true);
    } else {
      setShowLoading(true);
      API.userLogin(authData)
        .then((result) => {
          dispatch(getAvatar());
          localStorage.setItem("token", result.data.access_token);
          API.getUser(userName)
            .then((result) => {
              setShowLoading(false);
              user.setIsLoggedIn(true);
              localStorage.setItem("logged-user", result.data.data.firstName);
              dispatch(storeProfile(result.data.data));
            })
            .catch((error) => {});
          
          let startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          dispatch(getHoldings(userName));
          dispatch(getActiveNFOs());
          dispatch(getClosedNFOs());
          dispatch(getTransactions(userName, startDate, endDate));
        })
        .catch((error) => {
          setShowLoading(false);
          setLoginError(true);
        });
    }
  };

  return (
    <IonPage>
      <IonContent class="ion-no-padding ion-no-margin">
        <IonGrid
          class="ion-no-padding ion-no-margin"
          className="grid-max-height"
        >
          <IonRow className="row-max-height">
            <IonCol
              class="ion-text-center ion-hide-lg-down"
              className="web-wrapper max-height"
              size="12"
              size-lg="7"
              size-xl="8"
            >
              <br />
              <br />
              <br />
              <h1>Welcome to Kashly</h1>
              <p>
                A smart application for the smart investors. One single
                application for all the platforms. Let it be Android, iOS,
                Windows or even Web.
              </p>
              <div className="web-content-footer">
                <p>
                  Kashly is only an aggregation platform that collects
                  investment details of our customers from the mutual fund
                  registrars. The application is not an order placement platform
                  hence Kashly is not liable or responsible and does not
                  represent or warrant for any damages regarding non-execution
                  of orders or any incorrect execution of orders with regard to
                  the funds that happen outside of our platform.
                  <br />
                  <br />
                  <br />
                  <b>Disclaimer</b>
                  <br />
                  Mutual Funds investments are subject to market risks, read all
                  scheme related documents carefully. Past performance is not an
                  indicator for future returns.
                </p>
              </div>
            </IonCol>
            <IonCol
              size="12"
              size-lg="5"
              size-xl="4"
              class="ion-padding"
              className="max-height"
            >
              <div className="login-wrapper">
                <IonList>
                  <div className="login-header">
                    <div className="kashly-logo">
                      <img
                        src="/assets/images/kashly-logo.png"
                        alt="kashly-login"
                      />
                    </div>
                    <h1>Kashly</h1>
                    <p>It's easy to plant but difficult to grow!</p>
                  </div>
                  <IonInput
                    value={userName.toUpperCase()}
                    placeholder="Username"
                    onIonChange={(e: any) => setUserName(e.target.value)}
                    required
                  />
                  <IonInput
                    value={password}
                    placeholder="Password"
                    onIonChange={(e: any) => setPassword(e.target.value)}
                    required
                    type="password"
                  />
                  <IonButton
                    id="login-button"
                    expand="block"
                    fill="solid"
                    routerLink="/"
                    onClick={loginClick}
                  >
                    <b>Login</b>
                  </IonButton>
                  <a href="/forgot">
                    <p>Forgot Password?</p>
                  </a><br/><br/>
                  <p className="app-version">Application Version V1.4.3</p>
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonToast
            color="warning"
            isOpen={loginError}
            onDidDismiss={() => setLoginError(false)}
            message="Invalid username or password"
            position="bottom"
            duration={1000}
          />
        </IonGrid>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
