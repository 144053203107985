import React, { useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../store/reducers";
import { useSelector } from "react-redux";
import { Point, Area } from "react-easy-crop/types";
import Cropper from "react-easy-crop";
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonRange,
} from "@ionic/react";
import {
  addCircleOutline,
  alertCircleOutline,
  cameraOutline,
  helpCircleOutline,
  powerOutline,
  removeCircleOutline,
} from "ionicons/icons";

import Header from "../components/Header";
import API from "../api";
import "./Account.scss";

import { getAvatar } from "../store/actions";

const Account: React.FC = () => {
  const userProfile: any = useSelector<RootState>((state) => state.user.user);
  const avatar: any = useSelector<RootState>((state) => state.user.avatar);
  const [showModal, setShowModal] = useState(false);
  const [changePhoto, setChangePhoto] = useState(false);
  const [image, setImage] = useState<string>("");
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(3);
  const imgRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const profile = {
    user: userProfile.userName,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
    created: userProfile.createdAt,
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      console.log(croppedArea, croppedAreaPixels);
      setCroppedArea(croppedAreaPixels);
    },
    []
  );

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc: string, crop: Area) => {
    const image = (await createImage(imageSrc)) as ImageBitmap;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    canvas.width = 500;
    canvas.height = 500;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const onSelectFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result as string);
        setChangePhoto(true);
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setChangePhoto(false);
  };

  const handleChange = () => {
    selectImage();
  };

  const handleApply = () => {
    getCroppedImg(image, croppedArea as Area).then((data) => {
      let formData = new FormData();
      formData.append("file", data as Blob);
      API.changeAvatar(formData)
        .then((result) => {
          dispatch(getAvatar());
          setChangePhoto(false);
          setShowModal(false);
        })
        .catch((error) => {
          console.log("failed");
        });
    });
  };

  const handleEdit = () => {
    setImage("/assets/images/avatar.svg");
    setShowModal(true);
  };

  const selectImage = () => imgRef.current?.click();

  return (
    <>
      <IonPage>
        <Header title="Account" />
        <IonContent fullscreen className="disable-scrollbar">
          <div className="account-header">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <div className="account-avatar">
                    <img
                      src={
                        avatar
                          ? URL.createObjectURL(avatar)
                          : "/assets/images/avatar.svg"
                      }
                    />
                    <div className="avatar-edit" onClick={handleEdit}>
                      <IonIcon color="light" icon={cameraOutline} />
                    </div>
                  </div>

                  <h1>{profile.firstName + " " + profile.lastName}</h1>
                  <p style={{margin:"0px"}}>{profile.user}</p>
                </IonCol>
              </IonRow>
              <br />
              <br />
              <IonRow>
                <IonCol>
                  <IonList>
                    <IonItem
                      detail={false}
                      lines="full"
                      href="https://kashly.in/terms"
                      target="_blank"
                    >
                      <IonIcon slot="start" icon={alertCircleOutline} />
                      <IonLabel>Terms & Conditions</IonLabel>
                    </IonItem>
                    <IonItem
                      detail={false}
                      lines="full"
                      href="https://support.kashly.in"
                      target="_blank"
                    >
                      <IonIcon slot="start" icon={helpCircleOutline} />
                      <IonLabel>Support</IonLabel>
                    </IonItem>
                    <IonItem
                      detail={false}
                      lines="full"
                      button
                      routerLink="/logout"
                    >
                      <IonIcon slot="start" icon={powerOutline} />
                      <IonLabel>Logout</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
              <br />
              <br />
            </IonGrid>
          </div>
          <div className="brand-page-header" />
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText color="primary" class="ion-text-center">
                  <h4 className="ion-no-padding ion-no-margin">
                    <b>Kashly</b>
                  </h4>
                </IonText>
                <IonText
                  color="medium"
                  class="ion-text-center"
                  className="text-xs"
                >
                  <p className="ion-no-padding ion-no-margin">ARN-186781</p>
                </IonText>
                <br />
                <IonText class="ion-text-center">
                  <p className="text-xxs">
                    <br />
                    Kashly (Capsquare Technologies Private Limited) is an AMFI
                    registered advisor of Mutual Funds <br />
                    CIN-U72900KL2021PTC068649
                  </p>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonModal
            isOpen={showModal}
            cssClass="image-upload-modal"
            onDidDismiss={() => setShowModal(false)}
          >
            <input
              type="file"
              accept="image/*"
              ref={imgRef}
              style={{ display: "none" }}
              onChange={onSelectFile}
            />

            {changePhoto ? (
              <div className="image-crop-container">
                <div className="image-cropper">
                  <Cropper
                    image={image}
                    crop={crop}
                    cropSize={{ width: 200, height: 200 }}
                    zoom={zoom}
                    aspect={1}
                    showGrid={false}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>

                <IonRange
                  className="zoom-range"
                  min={1}
                  max={5}
                  value={zoom}
                  onIonChange={(e) => setZoom(e.detail.value as number)}
                >
                  <IonIcon
                    size="small"
                    slot="start"
                    icon={removeCircleOutline}
                  />
                  <IonIcon size="small" slot="end" icon={addCircleOutline} />
                </IonRange>
                <IonButton onClick={handleApply}>Apply</IonButton>
                <IonButton onClick={handleCancel}>Cancel</IonButton>
              </div>
            ) : (
              <div className="image-upload-container">
                <div className="current-avatar">
                <img src={avatar ? URL.createObjectURL(avatar) : "/assets/images/avatar.svg"} />
                </div>
                <br />
                <p className="text-xs">
                  We recommed you to upload 200x200 image as your profile
                  picture with size less than 2MB.
                </p>
                <IonButton onClick={handleChange}>Change</IonButton>
              </div>
            )}
          </IonModal>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Account;
