import React, { useEffect, useState } from "react";
import {
  IonBadge,
  IonCol,
  IonModal,
  IonRow,
  IonText,
  IonButton,
  IonGrid,
  IonCard,
} from "@ionic/react";
import Calendar from "react-calendar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers";
import { Link } from "react-router-dom";
import { getTransactions } from "../store/actions";
import {
  getAMCIcon,
  formatScheme,
  currencyFormat,
  formatAmount,
} from "../services/Utilities";
import moment from "moment";
import "../theme/calendar.scss";
import "./Transactions.scss";
import SectionHeader from "./SectionHeader";

interface TransactionProps {
  transactions?: any;
  startDate?: string;
  endDate?: string;
}
const Transactions: React.FC<TransactionProps> = ({
  transactions,
  startDate,
  endDate,
}) => {
  const userProfile: any = useSelector<RootState>((state) => state.user.user);

  const [showModal, setShowModal] = useState(false);
  const [fromDate, setFromDate] = useState({
    standard: "",
    display: "",
    calendar: "",
  });
  const [toDate, setToDate] = useState({
    standard: "",
    display: "",
    calendar: "",
  });

  const [dateRange, setDateRange] = useState([new Date()]);

  useEffect(() => {
    if (dateRange.length == 2) {
      /* timezone correction */
      let tzFromDate = new Date(
        dateRange[0].getTime() - dateRange[0].getTimezoneOffset() * 60000
      ).toJSON();
      let tzToDate = new Date(
        dateRange[1].getTime() - dateRange[1].getTimezoneOffset() * 60000
      ).toJSON();

      let fromDateFormatted = dateRange[0].toDateString().substr(4, 12);
      let toDateFormatted = dateRange[1].toDateString().substr(4, 12);

      setFromDate({
        ...fromDate,
        standard: tzFromDate.substr(0, 10),
        calendar: fromDateFormatted,
      });
      setToDate({
        ...toDate,
        standard: tzToDate.substr(0, 10),
        calendar: toDateFormatted,
      });
    } else {
      setFromDate({
        standard: "",
        display: new Date(startDate!).toDateString().substr(4, 12),
        calendar: new Date(startDate!).toDateString().substr(4, 12),
      });

      setToDate({
        standard: "",
        display: new Date(endDate!).toDateString().substr(4, 12),
        calendar: new Date(endDate!).toDateString().substr(4, 12),
      });
    }
  }, [dateRange]);

  const dispatch = useDispatch();

  const getDisplayDate = (date: string) => {
    var displayDate = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY");
    return new Date(displayDate!).toDateString().substr(4, 12);
  };

  const searchTransactions = (
    searchStartDate: string,
    searchEndDate: string
  ) => {
    if (searchStartDate !== "" && searchEndDate !== "") {
      dispatch(
        getTransactions(userProfile.userName, searchStartDate, searchEndDate)
      );
      setFromDate({
        ...fromDate,
        display: new Date(searchStartDate!).toDateString().substr(4, 12),
      });
      setToDate({
        ...toDate,
        display: new Date(searchEndDate!).toDateString().substr(4, 12),
      });
      setShowModal(false);
    }
  };

  const showTransactions = (transactions: any) => {
    let trxns: any = [];
    if (transactions != null) {
      transactions.map((trxn: any, key: any) => {
        trxns.push(
          <div className="transaction-entry">
            <Link to={"/transaction-details/" + trxn.transactionId}>
              <IonCard className="transaction-card">
                <IonRow>
                  <IonCol size="1">
                    <div className="amc-logo">
                      <img src={getAMCIcon(trxn.rtaAmcCode)} />
                    </div>
                  </IonCol>
                  <IonCol size="11">
                    <IonRow>
                      <IonCol>
                        <h3>{formatScheme(trxn.rtaSchemeName)}</h3>
                        <IonRow>
                          <IonCol size="12" size-md="6" size-lg="4">
                            <p>
                              &#8377;
                              {formatAmount(trxn.amount) +
                                " on " +
                                getDisplayDate(
                                  trxn.transactionDate.substr(0, 10)
                                )}
                            </p>
                          </IonCol>
                          <IonCol size="12" size-md="6" size-lg="8">
                            <span className="trxn-badge text-xs">
                              {trxn.transactionType}
                            </span>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCard>
            </Link>
          </div>
        );
      });
    }
    return trxns;
  };

  return (
    <>
      <IonRow className="transaction-section-header">
        <IonCol>
          <SectionHeader
            title="Your Transactions"
            subTitle={
              "Showing transactions from " +
              fromDate.display +
              " to " +
              toDate.display
            }
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            Search Transactions
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol></IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          {transactions.length > 0 ? (
            showTransactions(transactions)
          ) : (
            <>
              <div className="app-empty-transactions">
                <img
                  src="/assets/images/no-transactions.svg"
                  alt="empty-transactions"
                />
              </div>
              <br />
              <h6 className="ion-text-center">No Transactions</h6>
              <p className="ion-text-center text-xs">
                No transactions for the search period.
              </p>
            </>
          )}
        </IonCol>
      </IonRow>
      <IonModal
        isOpen={showModal}
        cssClass="date-picker"
        onDidDismiss={() => setShowModal(false)}
      >
        <div className="calendar-header">
          <IonGrid>
            <IonRow>
              <IonCol>
                <h5>From</h5>
                <span className="text-xs">{fromDate.calendar}</span>
              </IonCol>
              <IonCol>
                <h5>To</h5>
                <span className="text-xs">{toDate.calendar}</span>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonRow>
          <IonCol>
            <p className="text-xs">
              Choose start &amp; end dates, then click the apply button
            </p>
          </IonCol>
        </IonRow>
        <Calendar
          selectRange={true}
          returnValue="range"
          onChange={setDateRange}
        />
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              color="light"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              expand="block"
              color="primary"
              onClick={(e) => {
                searchTransactions(fromDate.standard, toDate.standard);
              }}
            >
              Apply
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>
    </>
  );
};

export default Transactions;
