import * as actions from "./actions";
import API from "../../api";
import { getImage } from "../../services/Utilities";

export const storeProfile = (data: any) => {
  return {
    type: actions.PROFILE,
    data: data,
  };
};
export const storeAvatar = (data: any) => {
  return {
    type: actions.AVATAR,
    data: data,
  };
};

export const storeUserProfile = (userData: any) => {
  return function (dispatch: any) {
    dispatch(storeProfile(userData));
  };
};

export const getAvatar = () => {
  return function (dispatch: any) {
    API.getAvatar()
      .then((result) => {
        dispatch(storeAvatar(result.data));
      })
      .catch((error) => {
        console.log("failed to retrieve avatar");
      });
  };
};
