import * as actions from "../actions/actions";

const initialState = {
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const workerUpdate = (state: any, action: any) => {
    return {
      ...state,
      serviceWorkerUpdated: !state.serviceWorkerUpdated,
      serviceWorkerRegistration: action.data,
    };
  };

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SWUPDATE:
      return workerUpdate(state, action);
    default:
      return state;
  }
};

export default reducer;
