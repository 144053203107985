import React from "react";
import { IonContent, IonPage, IonGrid } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";

import moment from "moment";
import Transactions from "../components/Transactions";
import Header from "../components/Header";

const Trans: React.FC = () => {
  const transactions: any = useSelector<RootState>(
    (state) => state.trxns.transactions
  );

  let startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
  let endDate = moment().format("YYYY-MM-DD");

  return (
    <IonPage>
      <Header title="Transactions" />
      <IonContent className="disable-scrollbar">
        <IonGrid>
          <Transactions
            transactions={transactions}
            startDate={startDate}
            endDate={endDate}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Trans;
