import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonItem,
  IonBadge,
  IonCard,
} from "@ionic/react";

import { useParams } from "react-router";

import Header from "../components/Header";
import {
  getAMCIcon,
  formatScheme,
  currencyFormat,
} from "../services/Utilities";
import "./TransactionDetails.scss";
import SectionHeader from "../components/SectionHeader";

const TransactionDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const trxns: any = useSelector<RootState>(
    (state) => state.trxns.transactions
  );
  const [transaction, setTransaction] = useState<any>({
    reference: "",
    schemeName: "",
    folioNumber: "",
    amcCode: "",
    amount: 0,
    status: "",
    units: 0,
    price: 0,
    transactionDate: "",
    processedDate: "",
    transactionType: "",
    isinNumber: "",
  });

  useEffect(() => {
    if (trxns != null) {
      trxns.map((trxn: any, key: any) => {
        if (trxn.transactionId == id) {
          setTransaction({
            reference: trxn.rtaOrderReference,
            schemeName: trxn.rtaSchemeName,
            folioNumber: trxn.folioNumber,
            amcCode: trxn.rtaAmcCode,
            amount: trxn.amount,
            status: trxn.status,
            units: trxn.units,
            price: trxn.purchasePrice,
            transactionDate: trxn.transactionDate,
            processedDate: trxn.processedDate,
            transactionType: trxn.transactionType,
            isinNumber: trxn.isin,
          });
        }
      });
    }
  }, [id]);

  return (
    <IonPage>
      <Header title="Transaction details" disableMenu={true} />
      <IonContent fullscreen className="disable-scrollbar">
      <div className="page-header">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol size="12">
                    <SectionHeader title={formatScheme(transaction.schemeName)} subTitle={"Folio : " + transaction.folioNumber}/>
                    <IonBadge color="primary">Completed</IonBadge>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonGrid>
          <IonCard className="transaction-details-card">
              <div className="transaction-entry">
                <IonList>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Amount</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{currencyFormat(transaction.amount)}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Price</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{currencyFormat(transaction.price)}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Units</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{transaction.units}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Transacted on</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{transaction.transactionDate.substr(0, 10)}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Processed on</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{transaction.processedDate.substr(0, 10)}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>Type</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{transaction.transactionType}</h6>
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonText slot="start">
                      <h6>ISIN</h6>
                    </IonText>
                    <IonText slot="end">
                      <h6>{transaction.isinNumber}</h6>
                    </IonText>
                  </IonItem>
                </IonList>
              </div>
          </IonCard>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default TransactionDetails;
