import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonToast,
  IonText,
  IonLoading,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import API from "../api";
import { validateResetForm } from "../services/AuthServices";
import "./Reset.scss";

const Reset: React.FC = () => {
  let url = window.location.href;
  let baseURL = url.substr(0, url.indexOf("?"));
  let params = new URLSearchParams(url);
  let token = params.get(baseURL + "?token");

  const [resetForm, setResetForm] = useState({
    newPassword: "",
    confirmPassword: "",
    loading: false,
    error: false,
    message: "",
    resetIcon: "/assets/images/password.svg",
    resetText: "",
    status: "",
  });
  const apiData = {
    token: token,
    password: resetForm.confirmPassword,
  };
  const changePasswordClick = () => {
    let validationError = validateResetForm(resetForm);

    if (validationError === "" && token !== null) {
      setResetForm({
        ...resetForm,
        loading: true,
      });

      API.resetPassword(apiData)
        .then((result) => {
          if (result.data.status === "success") {
            setResetForm({
              ...resetForm,
              loading: false,
              status: "success",
              resetIcon: "/assets/images/completed.svg",
              resetText:
                "Your password has been updated successfully. Continue with login.",
            });
          } else {
            setResetForm({
              ...resetForm,
              loading: false,
              resetText: "",
              error: true,
              message: "Password change attempt failed!",
            });
          }
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response.status === 401) {
            errorMessage = "Your reset request expired, try again later!";
          } else {
            errorMessage = "Password change attempt failed!";
          }
          setResetForm({
            ...resetForm,
            loading: false,
            error: true,
            message: errorMessage,
          });
          console.log(errorMessage);
        });
    } else {
      setResetForm({
        ...resetForm,
        error: true,
        message: token === null ? "Technical Error" : validationError,
      });
    }
  };

  const showResetForm = () => {
    return (
      <>
        <IonInput
          placeholder="New Password"
          value={resetForm.newPassword}
          onIonChange={(e: any) =>
            setResetForm({
              ...resetForm,
              newPassword: e.target.value,
            })
          }
          required
          type="password"
        />
        <IonInput
          placeholder="Confirm Password"
          value={resetForm.confirmPassword}
          onIonChange={(e: any) =>
            setResetForm({
              ...resetForm,
              confirmPassword: e.target.value,
            })
          }
          required
          type="password"
        />
        <p />
        <br />
        <IonButton fill="solid" expand="block" onClick={changePasswordClick}>
          <b>Change Password</b>
        </IonButton>
        <br />
        <IonText class="ion-text-center" className="text-s">
          <a href="/login">
            <p>Login</p>
          </a>
        </IonText>
      </>
    );
  };

  const showLoginButton = () => {
    return (
      <IonRow>
        <IonCol />
        <IonCol>
          <IonButton fill="solid" expand="block" routerLink="/login">
            <b>Login</b>
          </IonButton>
        </IonCol>
        <IonCol />
      </IonRow>
    );
  };
  return (
    <IonPage>
      <IonContent class="ion-no-padding ion-no-margin">
        <IonGrid
          class="ion-no-padding ion-no-margin"
          className="grid-max-height"
        >
          <IonRow className="row-max-height">
            <IonCol
              class="ion-text-center ion-hide-lg-down"
              className="web-wrapper max-height"
              size="12"
              size-lg="7"
              size-xl="8"
            >
              <br />
              <br />
              <br />
              <h1>Welcome to Kashly</h1>
              <p>
                A smart application for the smart investors. One single
                application for all the platforms. Let it be Android, iOS,
                Windows or even Web.
              </p>
              <div className="web-content-footer">
                <p>
                  Kashly is only an aggregation platform that collects
                  investment details of our customers from the mutual fund
                  registrars. The application is not an order placement platform
                  hence Kashly is not liable or responsible and does not
                  represent or warrant for any damages regarding non-execution
                  of orders or any incorrect execution of orders with regard to
                  the funds that happen outside of our platform.
                  <br />
                  <br />
                  <br />
                  <b>Disclaimer</b>
                  <br />
                  Mutual Funds investments are subject to market risks, read all
                  scheme related documents carefully. Past performance is not an
                  indicator for future returns.
                </p>
              </div>
            </IonCol>
            <IonCol
              size="12"
              size-lg="5"
              size-xl="4"
              class="ion-padding"
              className="max-height"
            >
              <div className="reset-wrapper">
                <div className="reset-header">
                  <div className="kashly-logo">
                    <img src={resetForm.resetIcon} />
                  </div>
                  <h1 className="ion-no-padding ion-no-margin">
                    {resetForm.resetText === ""
                      ? "New Password"
                      : "Password Changed"}
                  </h1>
                  <p>{resetForm.resetText}</p>
                </div>
                <IonList>
                  {resetForm.status === "success"
                    ? showLoginButton()
                    : showResetForm()}
                </IonList>
              </div>
            </IonCol>
          </IonRow>

          <IonToast
            color="warning"
            isOpen={resetForm.error}
            onDidDismiss={() => setResetForm({ ...resetForm, error: false })}
            message={resetForm.message}
            position="bottom"
            duration={1000}
          />
        </IonGrid>

        <IonLoading
          isOpen={resetForm.loading}
          onDidDismiss={() => setResetForm({ ...resetForm, loading: false })}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default Reset;
