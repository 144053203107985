import * as actions from "../actions/actions";

const initialState = {
  transactions: null,
  activeNFO: null,
  closedNFO: null,

};

const storeTransactions = (state: any, action: any) => {
  return {
    ...state,
    transactions: action.data,
  };
};


const storeActiveNFOs = (state: any, action: any) => {
  return {
    ...state,
    activeNFO: action.data,
  };
};

const storeClosedNFOs = (state: any, action: any) => {
  return {
    ...state,
    closedNFO: action.data,
  };
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.TRANSACTIONS:
      return storeTransactions(state, action);
    case actions.ACTIVE_NFO:
      return storeActiveNFOs(state, action);
    case actions.CLOSED_NFO:
      return storeClosedNFOs(state, action);
    default:
      return state;
  }
};

export default reducer;
