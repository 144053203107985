import isEmail from "validator/lib/isEmail";

export const validateResetRequestForm = (resetForm: any) => {
  let error = "";
  if (resetForm.email === "") {
    error = "Please provide your registered email";
  } else if (!isEmail(resetForm.email)) {
    error = "Invalid email address";
  }
  return error;
};

export const validateResetForm = (resetForm: any) => {
  let error = "";
  if (resetForm.newPassword === "" || resetForm.confirmPassword === "") {
    error = "Password can not be empty";
  } else if (resetForm.newPassword.length < 6) {
    error = "Password length should be minimum 6 characters";
  } else if (resetForm.newPassword !== resetForm.confirmPassword) {
    error = "Passwords are not matching";
  }
  return error;
};
